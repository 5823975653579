<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8956 11.6667C15.8445 11.6667 16.6195 12.4129 16.6643 13.3512L16.6663 13.437V13.75C16.6663 15.3148 15.8573 16.4862 14.6163 17.2418C13.3959 17.9847 11.7555 18.3334 9.99967 18.3334C8.24388 18.3334 6.60347 17.9847 5.38309 17.2418C4.17964 16.5091 3.38244 15.3854 3.33523 13.8912L3.33301 13.75V13.4371C3.33301 12.4877 4.08007 11.7134 5.01802 11.6687L5.1038 11.6667H14.8956ZM9.99967 1.66669C12.3008 1.66669 14.1663 3.53217 14.1663 5.83335C14.1663 8.13452 12.3008 10 9.99967 10C7.69851 10 5.83301 8.13452 5.83301 5.83335C5.83301 3.53217 7.69851 1.66669 9.99967 1.66669Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props:{
    fill:{
      type:String,
      default:'#0a9250'
    }
  }
}
</script>
