<template>
  <BContainer>
    <Spinner v-if="getLoadings.rent_log" />
    <template v-else>
      <CardV2 class="mb-4">
        <div class="fs-22px mb-3" v-if="listing">
          <strong>Rent Log: {{ listing.title }}</strong>
        </div>
        <p class="text-gray">
          The Rent Log is a digital overview of all information regarding a housing
          transaction. Using the Rent Log, the parties involved can view all actions in
          the sales process afterwards and, if necessary, check them.
        </p>
      </CardV2>

      <CardV2 class="mb-4">
        <div class="d-flex">
          <div class="fs-22px mb-3">
            <TickBox />
          </div>
          <div>
            <div class="fs-22px mb-3">
              <strong class="ml-2">A tenant has been assigned</strong>
            </div>
            <p class="text-gray">
              A total of {{ assignings.length }} have signed up for this property:
            </p>
          </div>
        </div>
      </CardV2>

      <CardV2 class="mb-4 p-0">
        <table class="table table-hover bg-white">
          <thead>
            <tr>
              <th scope="col">User</th>
              <th scope="col">Income</th>
              <th scope="col">Credit check</th>
              <th scope="col">KYC</th>
              <th scope="col">Household</th>
              <th scope="col">Pets</th>
              <th scope="col">Tenant score</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(assigning, key) in assignings"
              :key="key"
              :class="{ 'highlighted-info': assigning.assigned }"
            >
              <td scope="row">{{ assigning.userCode }}</td>
              <td>
                <TickBox
                  id="sufficientIncome-professional"
                  fill="#ffcb00"
                  v-if="assigning.profesional_less_than_2"
                />
                <TickBox
                  id="sufficientIncome"
                  fill="#0a9250"
                  v-else-if="assigning.sufficientIncome"
                />
                <TimesBox id="notSufficientIncome" v-else />

                <b-tooltip
                  custom-class="custom-tooltip"
                  target="sufficientIncome-professional"
                  placement="right"
                >
                  Contains a tenant with less than 2 years entrepreneurship
                </b-tooltip>
                <b-tooltip
                  custom-class="custom-tooltip"
                  target="sufficientIncome"
                  placement="right"
                >
                  Income sufficient
                </b-tooltip>
                <b-tooltip
                  custom-class="custom-tooltip"
                  target="notSufficientIncome"
                  placement="right"
                >
                  Income not-sufficient
                </b-tooltip>
              </td>
              <td>
                <div
                  class="wh-20px rounded-circle m-auto"
                  :id="`riskcode-${assigning.riskcode}`"
                  :class="{
                    'bg-success': assigning.riskcode == 1,
                    'bg-warning': assigning.riskcode == 2,
                    'bg-danger': assigning.riskcode == 3,
                  }"
                ></div>
                <b-tooltip
                  custom-class="custom-tooltip"
                  target="riskcode-1"
                  placement="right"
                >
                  Overeenkomst op basis van krediet wordt verantwoord geacht
                </b-tooltip>
                <b-tooltip
                  custom-class="custom-tooltip"
                  target="notriskcode-3"
                  placement="right"
                >
                  Aangaan van een overeenkomst op basis van krediet geeft een verhoogd
                  risico
                </b-tooltip>
              </td>
              <td>
                <div
                  v-if="assigning.sanction == 'red'"
                  class="wh-20px m-auto rounded-circle bg-danger"
                  :id="`kyc-${key}-${assigning.sanction}`"
                ></div>
                <div
                  v-if="assigning.sanction == 'yellow'"
                  class="wh-20px m-auto rounded-circle bg-warning"
                  :id="`kyc-${key}-${assigning.sanction}`"
                ></div>
                <div
                  v-if="assigning.sanction == 'green'"
                  class="wh-20px m-auto rounded-circle bg-success"
                  :id="`kyc-${key}-${assigning.sanction}`"
                ></div>
                <b-tooltip
                  custom-class="custom-tooltip"
                  :target="`kyc-${key}-${assigning.sanction}`"
                  placement="right"
                  v-if="assigning.sanction == 'red'"
                >
                  <ul class="px-4 py-2 mb-0 list-style-dots">
                    <li v-for="flag in row.flags" :key="flag">{{ flag }}</li>
                  </ul>
                </b-tooltip>
                <b-tooltip
                  custom-class="custom-tooltip"
                  :target="`kyc-${key}-${assigning.sanction}`"
                  placement="right"
                  v-if="assigning.sanction == 'green'"
                >
                  No results found
                </b-tooltip>
              </td>
              <td>
                <Person
                  :fill="getHouseholdColor(assigning.householdComposition)"
                  :id="`household_${key}`"
                  v-if="assigning.householdComposition === 'single'"
                />
                <People
                  :id="`household_${key}`"
                  :fill="getHouseholdColor(assigning.householdComposition)"
                  v-else-if="assigning.householdComposition === 'couple'"
                />
                <Team
                  :fill="getHouseholdColor(assigning.householdComposition)"
                  :id="`household_${key}`"
                  v-else-if="assigning.householdComposition === 'family'"
                />
                <Group
                  :fill="getHouseholdColor(assigning.householdComposition)"
                  :id="`household_${key}`"
                  v-else
                />
                <b-tooltip
                  custom-class="custom-tooltip"
                  :target="`household_${key}`"
                  placement="top"
                >
                  <template v-if="assigning.householdComposition === 'single'">
                    Single
                  </template>
                  <template v-else-if="assigning.householdComposition === 'couple'">
                    Couple
                  </template>
                  <template v-else-if="assigning.householdComposition === 'family'">
                    Family
                  </template>
                  <template v-else> Group occupancy </template>
                </b-tooltip>
              </td>

              <td>
                <Paw
                  :fill="
                    listing.preferredAnimals == 0 && assigning.pets == 1
                      ? '#FE5F55'
                      : '#0a9250'
                  "
                  class="ml-2"
                />
              </td>
              <td>
                <span
                  class="fw-bold"
                  :style="{
                    color: getTenantScoreColor(assigning.userScore),
                  }"
                  >{{ assigning.userScore }}/10</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </CardV2>

      <CardV2 class="bg-gray text-white my-4">
        <div class="row">
          <div class="col-md-8">
            <div class="fs-22px mb-3">
              <strong>Right of selection</strong>
            </div>
            <p>
              Please note: As you can see in the Rent Log, more points play a role in the
              allocation of a tenant, such as the conditions and transfer preferences .
              The landlord is therefore free in the tenant’s choice, also referred to as
              the 'right of selection.
            </p>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-white text-white">More information</button>
          </div>
        </div>
      </CardV2>

      <CardV2 class="mb-4">
        <div class="d-flex">
          <div class="fs-22px mb-3">
            <TickBox />
          </div>
          <div>
            <div class="fs-22px mb-3">
              <strong class="ml-2">A tenant has been assigned</strong>
            </div>
            <p class="text-gray" v-if="listing">
              {{ listing.broker.officeName }} has assigned a tenant with the landlord on
              {{ listing.assigned_at }}.
            </p>
          </div>
        </div>
      </CardV2>

      <div class="row mb-5">
        <div class="col-md-12 text-center">
          <p class="text-gray">
            This Rent Log has been automatically generated | Copyright © 2023 | Eerlijk te
            Huur
          </p>
        </div>
      </div>
    </template>
  </BContainer>
</template>
<script>
import Person from "@/components/svg/Person.vue";
import People from "@/components/svg/People.vue";
import Team from "@/components/svg/Team.vue";
import Paw from "@/components/svg/Paw.vue";
import Group from "@/components/svg/Group.vue";
import TickBox from "@/components/svg/TickBox.vue";
import TimesBox from "@/components/svg/TimesBox.vue";
import { mapActions, mapGetters } from "vuex";
import Spinner from "../../../components/ui/Spinner.vue";
import CardV2 from "../../../components/general/CardV2.vue";

export default {
  props: {
    showAction: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Paw,
    Person,
    People,
    Team,
    TimesBox,
    TickBox,
    Group,
    Spinner,
    CardV2,
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing"]),
    ...mapGetters("loadingError", ["getLoadings"]),
    getSelectedRowData() {
      if (!this.selectedRowId) return null;
      return this.assignings.find((req) => req.id == this.selectedRowId);
    },
    listingId() {
      return this.$route.params.listing_id;
    },
    columns() {
      return [
        {
          label: "User",
          field: "user",
          tooltip: "A simple tooltip",
          html: true,
        },
        {
          label: "Income",
          field: "sufficientIncome",
          html: true,
          type: "boolean",
        },
        {
          label: "Credit check",
          // label: "Col3",
          field: "riskcode",
          html: true,
          type: "boolean",
        },
        // {
        //   // label: this.assigningLocale.tableCols[6],
        //   label: "Col4",
        //   field: "validated",
        //   html: true,
        //   type: "boolean",
        // },
        {
          label: "KYC",
          //   label: "Col4",
          field: "sanction",
          html: true,
          //   type: "boolean",
        },
        {
          label: "Household",
          field: "householdComposition",
          html: true,
        },
        {
          label: "Pets",
          field: "pets",
          html: true,
        },
        {
          label: "Tenant score",
          field: "userScore",
          html: true,
        },

        {
          label: "User",
          field: "userCode",
          hidden: true,
        },
        {
          label: "assigned",
          field: "assigned",
          hidden: true,
        },
        {
          label: "id",
          field: "id",
          hidden: true,
        },
        {
          label: "Flags",
          field: "flags",
          hidden: true,
        },
        {
          field: "profesional_less_than_2",
          hidden: true,
        },
      ];
    },
  },
  data() {
    return {
      selectedRowId: null,
    };
  },
  methods: {
    ...mapActions("report", ["reportCandidate"]),
    ...mapActions("requestsAndAppointment", [
      "setAssignings",
      "getRentLong",
      "assignCandidate",
      "cancelAssigning",
      "validateAssigning",
      "updateStatus",
    ]),
    onRowSelect(rowId) {
      this.selectedRowId = rowId;
      console.log("onrow", this.selectedRowId);
      this.$emit("rowSelect", rowId);
    },
    async assigningCancelled() {
      // await this.cancelAssigning({ listingId: this.listing.id });
      await this.updateStatus("Candidate selection");
      this.$router.push(`/broker/listings/${this.listing.id}`);
    },
    getHouseholdColor(household) {
      if (!this.listing || !household) return "#0a9250";
      return this.listing.preferredHousehold
        .toLowerCase()
        .indexOf(household.toLowerCase()) > -1
        ? "#0a9250"
        : "#FE5F55";
    },
    getTenantScoreColor(value) {
      if (value <= 3) return "#FE5F55";
      if (value > 3 && value <= 6) return "#FFCB00";
      return "#0a9250";
    },
    async candidateReport(body) {
      body.user_id = this.selectedRowId;
      await this.reportCandidate(body);
      this.$bvModal.hide("report-candidate-modal");
    },
    openAssignModal() {
      this.$bvModal.show("assign-candidate-modal");
      localStorage.setItem(`assign_modal_id_${this.listingId}`, this.selectedRowId);
    },
  },
  async created() {
    await this.getRentLong(this.listingId);
  },
};
</script>
<style lang="scss" scoped>
th,
td {
  text-align: center;
}

.highlighted-info {
  box-shadow: inset 0 0 0 2px $info;
  position: relative;

  td:first-of-type {
    &::before {
      @include pseudo(block, absolute, "Tenant");
      background: $info;
      width: 10rem;
      height: 30px;
      color: white;
      top: -30px;
      left: 0;
      padding-left: 11px;
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
}
</style>
