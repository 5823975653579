<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_253_127)">
      <path
        d="M9.99932 8.75003C6.89737 8.75003 2.49932 13.5453 2.49932 16.5723C2.49932 17.9356 3.54659 18.75 5.30167 18.75C7.20948 18.75 8.46924 17.7703 9.99932 17.7703C11.5427 17.7703 12.806 18.75 14.697 18.75C16.4521 18.75 17.4993 17.9356 17.4993 16.5723C17.4993 13.5453 13.1013 8.75003 9.99932 8.75003ZM4.2462 8.25745C3.83995 6.90394 2.58839 6.02738 1.45089 6.29925C0.313386 6.57113 -0.279192 7.8887 0.127058 9.24222C0.533308 10.5957 1.78487 11.4723 2.92237 11.2004C4.05987 10.9285 4.65245 9.61097 4.2462 8.25745ZM7.55557 7.44573C8.76417 7.12777 9.36885 5.49495 8.90635 3.79886C8.44385 2.10277 7.08917 0.985969 5.88057 1.30394C4.67198 1.62191 4.06729 3.25472 4.52979 4.95081C4.99229 6.64691 6.34737 7.76409 7.55557 7.44573ZM18.5474 6.29964C17.4099 6.02777 16.1587 6.90433 15.7521 8.25784C15.3458 9.61136 15.9384 10.9289 17.0759 11.2008C18.2134 11.4727 19.4646 10.5961 19.8712 9.24261C20.2774 7.88909 19.6849 6.57152 18.5474 6.29964ZM12.4431 7.44573C13.6517 7.7637 15.0064 6.64691 15.4689 4.95081C15.9314 3.25472 15.3267 1.6223 14.1181 1.30394C12.9095 0.985578 11.5548 2.10277 11.0923 3.79886C10.6298 5.49495 11.2345 7.12777 12.4431 7.44573Z"
        :fill="fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_253_127">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props:{
    fill:{
      type:String,
      default:'#FE5F55'
    }
  }
}
</script>