<template>
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6816 8.80002C13.3719 8.80002 13.9316 9.42683 13.9316 10.2V15.5992C13.9316 18.0297 12.1724 20 10.0023 20C7.83216 20 6.07295 18.0297 6.07295 15.5992V10.2C6.07295 9.42683 6.63259 8.80002 7.32295 8.80002H12.6816ZM2.67871 8.80002L5.80879 8.79848C5.55886 9.13679 5.39759 9.56025 5.36484 10.0242L5.35866 10.2V15.5992C5.35866 16.5059 5.56583 17.3584 5.93004 18.1006C5.53646 18.293 5.10133 18.4 4.64388 18.4C2.86819 18.4 1.42871 16.7878 1.42871 14.799V10.2C1.42871 9.42683 1.98836 8.80002 2.67871 8.80002ZM14.1957 8.79848L17.3216 8.80002C18.0119 8.80002 18.5716 9.42683 18.5716 10.2V14.8C18.5716 16.7882 17.1325 18.4 15.3573 18.4C14.9008 18.4 14.4665 18.2934 14.0733 18.1013L14.1131 18.0203C14.4193 17.3695 14.6059 16.6371 14.6401 15.8599L14.6459 15.5992V10.2C14.6459 9.66745 14.4769 9.17908 14.1957 8.79848ZM10.0001 2.40002C11.3809 2.40002 12.5001 3.65363 12.5001 5.20002C12.5001 6.74642 11.3809 8.00002 10.0001 8.00002C8.61943 8.00002 7.50014 6.74642 7.50014 5.20002C7.50014 3.65363 8.61943 2.40002 10.0001 2.40002ZM15.7166 3.20002C16.9 3.20002 17.8594 4.27454 17.8594 5.60002C17.8594 6.92551 16.9 8.00002 15.7166 8.00002C14.5331 8.00002 13.5737 6.92551 13.5737 5.60002C13.5737 4.27454 14.5331 3.20002 15.7166 3.20002ZM4.28372 3.20002C5.46719 3.20002 6.42658 4.27454 6.42658 5.60002C6.42658 6.92551 5.46719 8.00002 4.28372 8.00002C3.10026 8.00002 2.14087 6.92551 2.14087 5.60002C2.14087 4.27454 3.10026 3.20002 4.28372 3.20002Z"
      :fill="fill"
    />
  </svg>
</template>


<script>
export default {
  props:{
    fill:{
      type:String,
      default:'#FE5F55'
    }
  }
}
</script>