<template>
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.1875 3.4375C7.1875 4.64453 6.20703 5.625 5 5.625C3.7918 5.625 2.8125 4.64453 2.8125 3.4375C2.8125 2.2293 3.7918 1.25 5 1.25C6.20703 1.25 7.1875 2.2293 7.1875 3.4375ZM8.14062 7.66797C6.98047 8.69922 6.25 10.2031 6.25 11.875C6.25 13.2148 6.68359 14.4453 7.5 15.4102V16.25C7.5 16.9414 6.94141 17.5 6.25 17.5H3.75C3.05977 17.5 2.5 16.9414 2.5 16.25V15.2031C1.02188 14.5 0 12.9961 0 11.25C0 8.83203 1.95859 6.875 4.375 6.875H5.625C6.52734 6.875 7.42969 7.16797 8.14062 7.66797ZM2.5 9.59766C2.11094 10.0352 1.875 10.6172 1.875 11.25C1.875 11.8828 2.11094 12.4648 2.5 12.9023V9.59766ZM17.5 16.25V15.4102C18.2812 14.4453 18.75 13.2148 18.75 11.875C18.75 10.2031 18.0195 8.69922 16.8594 7.66797C17.5703 7.16797 18.4375 6.875 19.375 6.875H20.625C23.043 6.875 25 8.83203 25 11.25C25 12.9961 23.9766 14.5 22.5 15.2031V16.25C22.5 16.9414 21.9414 17.5 21.25 17.5H18.75C18.0586 17.5 17.5 16.9414 17.5 16.25ZM22.5 12.9023C22.8555 12.4648 23.125 11.8828 23.125 11.25C23.125 10.6172 22.8555 10.0352 22.5 9.59766V12.9023ZM22.1875 3.4375C22.1875 4.64453 21.207 5.625 20 5.625C18.793 5.625 17.8125 4.64453 17.8125 3.4375C17.8125 2.2293 18.793 1.25 20 1.25C21.207 1.25 22.1875 2.2293 22.1875 3.4375ZM10 3.75C10 2.36914 11.1211 1.25 12.5 1.25C13.8789 1.25 15 2.36914 15 3.75C15 5.12891 13.8789 6.25 12.5 6.25C11.1211 6.25 10 5.12891 10 3.75ZM17.5 11.875C17.5 13.6211 16.4766 15.125 15 15.8281V17.5C15 18.1914 14.4414 18.75 13.75 18.75H11.25C10.5586 18.75 10 18.1914 10 17.5V15.8281C8.52344 15.125 7.5 13.6211 7.5 11.875C7.5 9.45703 9.45703 7.5 11.875 7.5H13.125C15.543 7.5 17.5 9.45703 17.5 11.875ZM10 13.5273V10.2227C9.60938 10.6602 9.375 11.2422 9.375 11.875C9.375 12.5078 9.60938 13.0898 10 13.5273ZM15 10.2227V13.5273C15.3555 13.0859 15.625 12.5078 15.625 11.875C15.625 11.2422 15.3555 10.6602 15 10.2227Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#FE5F55",
    },
  },
};
</script>
