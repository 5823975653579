<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4487 0.0335693H4.55132C2.03744 0.0335693 0 2.07155 0 4.58543V20.4145C0 22.9289 2.03744 24.9664 4.55132 24.9664H20.4487C22.962 24.9664 25 22.9284 25 20.4145V4.58543C25.0005 2.07155 22.9626 0.0335693 20.4487 0.0335693ZM20.6004 10.3742L12.0013 18.9733C11.6366 19.3386 11.1417 19.5435 10.6257 19.5435C10.1097 19.5435 9.61484 19.3381 9.25006 18.9733L4.98818 14.7114C4.22882 13.9515 4.22882 12.72 4.98873 11.9596C5.3535 11.5948 5.84782 11.3899 6.36382 11.3899C6.87982 11.3899 7.37414 11.5948 7.73892 11.9596L10.2788 14.5C10.371 14.5922 10.4962 14.6442 10.6262 14.6442C10.7563 14.6442 10.8815 14.5922 10.9737 14.5L17.8497 7.62344C18.215 7.25866 18.7093 7.05323 19.2259 7.05323C19.7414 7.05378 20.2362 7.25866 20.601 7.62344C21.3604 8.38334 21.3604 9.61481 20.6004 10.3742Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#0a9250",
    },
  },
};
</script>
