<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.85728 11.4286L10.7144 11.4293C11.4672 11.4293 12.0845 12.0118 12.1391 12.7512L12.143 12.8579V14.6429C12.1423 17.6429 9.08085 18.5715 6.78585 18.5715C4.53675 18.5715 1.55089 17.6797 1.43235 14.8204L1.42871 14.6429V12.8572C1.42871 12.1044 2.01185 11.4871 2.75072 11.4325L2.85728 11.4286ZM12.3001 11.4293L17.143 11.4286C17.8957 11.4286 18.5131 12.0117 18.5676 12.7506L18.5716 12.8572V14.2857C18.5709 16.9707 16.1216 17.8572 14.2859 17.8572C13.5301 17.8572 12.6723 17.7036 11.9223 17.3393C12.4596 16.7006 12.8084 15.8878 12.8525 14.8652L12.8573 14.6429V12.8579C12.8573 12.3566 12.6796 11.9002 12.3902 11.5358L12.3001 11.4293L17.143 11.4286L12.3001 11.4293ZM6.78585 2.14288C8.95514 2.14288 10.7144 3.90217 10.7144 6.07145C10.7144 8.24074 8.95514 10 6.78585 10C4.61657 10 2.85728 8.24074 2.85728 6.07145C2.85728 3.90217 4.61657 2.14288 6.78585 2.14288ZM14.643 3.57145C16.418 3.57145 17.8573 5.01074 17.8573 6.78574C17.8573 8.56074 16.418 10 14.643 10C12.868 10 11.4287 8.56074 11.4287 6.78574C11.4287 5.01074 12.868 3.57145 14.643 3.57145Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props:{
    fill:{
      type:String,
      default:'#0a9250'
    }
  }
}
</script>